export const teamDatas = [
    {
        id: 1,
        name: "Saint-Quentin",
        salle: "Palais des sports Pierre Ratte",
        logo: "https://www.proballers.com/api/getTeamLogo?id=38&width=160"
    },
    {
        id: 2,
        name: "Metropolitans 92",
        salle: "Palais des sports Marcel-Cerdan",
        logo: "https://www.proballers.com/api/getTeamLogo?id=8&width=160"
    },
    {
        id: 3,
        name: "Paris Basketball",
        salle: "Adidas Arena",
        logo: "https://www.proballers.com/api/getTeamLogo?id=13219&width=160"
    },
    {
        id: 4,
        name: "Monaco",
        salle: "Salle Gaston Médecin",
        logo: "https://www.proballers.com/api/getTeamLogo?id=283&width=160"
    },
    {
        id: 5,
        name: "SIG",
        salle: "Rhénus Sport",
        logo: "https://www.proballers.com/api/getTeamLogo?id=17&width=160"
    },
    {
        id: 6,
        name: "Lyon Villeurbanne",
        salle: "Astroballe",
        logo: "https://www.proballers.com/api/getTeamLogo?id=1&width=160"
    },
    {
        id: 7,
        name: "Elan Chalon",
        salle: "Le Colisée",
        logo: "https://www.proballers.com/api/getTeamLogo?id=12&width=160"
    },
    {
        id: 8,
        name: "BCM Graveline Dunckerque",
        salle: "Sportica",
        logo: "https://www.proballers.com/api/getTeamLogo?id=5&width=160"
    },
    {
        id: 9,
        name: "Sluc Nancy",
        salle: "Jean Weille de Gentilly",
        logo: "https://www.proballers.com/api/getTeamLogo?id=15&width=160"
    },
    {
        id: 10,
        name: "CSP Limoges",
        salle: "Palais des Sports de Beaublanc",
        logo: "https://www.proballers.com/api/getTeamLogo?id=6&width=160"
    },
    {
        id: 11,
        name: "Nanterre 92",
        salle: "Palais des Sports Maurice Thorez",
        logo: "https://www.proballers.com/api/getTeamLogo?id=261&width=160"
    },
    {
        id: 12,
        name: "JL Bourg",
        salle: "Ekinox;",
        logo: "https://www.proballers.com/api/getTeamLogo?id=21&width=160"
    },
    {
        id: 13,
        name: "JDA Dijon",
        salle: "Palais Jean-Michel-Geoffroy",
        logo: "https://www.proballers.com/api/getTeamLogo?id=11&width=160"
    },
    {
        id: 14,
        name: "Cholet Basket",
        salle: "Parc des Expositions de Cholet",
        logo: "https://www.proballers.com/api/getTeamLogo?id=4&width=150"
    },
    {
        id: 15,
        name: "ADA Blois",
        salle: "Salle du Jeu de Paume",
        logo: "https://www.proballers.com/api/getTeamLogo?id=289&width=150"
    },
    {
        id: 16,
        name: "Chorale Roanne Basket ",
        salle: "Halle Andre Vacheresse",
        logo: "https://www.proballers.com/api/getTeamLogo?id=28&width=150"
    },
    {
        id: 17,
        name: "Le Mans",
        salle: "Antarès",
        logo: "https://www.proballers.com/api/getTeamLogo?id=9&width=160"
    },
    {
        id: 18,
        name: "Le Portel",
        salle: "Le Chaudron",
        logo: "https://www.proballers.com/api/getTeamLogo?id=347&width=160"
    },
    {
        id: 19,
        name: "Nantes",
        salle: "Métropolitaine de la Trocardière",
        logo: "https://www.proballers.com/api/getTeamLogo?id=25&width=160"
    },
    {
        id: 20,
        name: "Kolossos",
        salle: "Palais des Sports Kallithea",
        logo: "https://www.proballers.com/api/getTeamLogo?id=1039&width=160"
    },
    {
        id: 21,
        name: "Pinar Karsiyaka",
        salle: "Karsiyaka Arena",
        logo: "https://www.proballers.com/api/getTeamLogo?id=523&width=160"
    },
    {
        id: 22,
        name: "Stade Rochelais",
        salle: "Gaston-Neveur",
        logo: "https://www.proballers.com/api/getTeamLogo?id=242&width=160"
    },
    {
        id: 23,
        name: "La Laguna Tenerife",
        salle: "Tenerife Sports Pavilion Santiago Martin",
        logo: "https://www.proballers.com/api/getTeamLogo?id=917&width=160"
    },
    {
        id: 24,
        name: "Strasbourg",
        salle: "Rhénus Sport",
        logo: "https://www.proballers.com/api/getTeamLogo?id=17&width=160"
    },
    {
        id: 25,
        name: "Galatasaray",
        salle: "Palais des Sports Kallithea",
        logo: "https://www.proballers.com/api/getTeamLogo?id=553&width=160"
    }
]