import React from "react";
import "./StaffContainer.css";
import { Swiper, SwiperSlide } from "swiper/react";
import coachImage from "../../../img/julien_mahe.png";
import adjointImage from "../../../img/dominique-roinard.jpg";
import preparateur from "../../../img/staff/lecompte/lecompte_main_1.webp";
//import newAdjoint from "../../../img/staff/karim/karim.png";

import coach1 from "../../../img/staff/coach/mahe_2.png";
import coach2 from "../../../img/staff/coach/mahe_3.png";
import coach3 from "../../../img/staff/coach/mahe_4.png";
import coach4 from "../../../img/staff/coach/mahe_5.png";
import coach5 from "../../../img/staff/coach/mahe_6.png";
import coach6 from "../../../img/staff/coach/mahe_7.png";

import adjoint1 from "../../../img/staff/adjoint/montant_1.jpg";
/*import adjoint2 from "../../../img/staff/adjoint/montant_3.png";
import adjoint3 from "../../../img/staff/adjoint/montant_4.png";
import adjoint4 from "../../../img/staff/adjoint/montant_5.png";
import adjoint5 from "../../../img/staff/adjoint/montant_6.png";*/

import newAdjoint1 from "../../../img/staff/karim/karim1.webp";
import newAdjoint2 from "../../../img/staff/karim/karim2.webp";
import newAdjoint3 from "../../../img/staff/karim/karim3.webp";
import newAdjoint4 from "../../../img/staff/karim/karim4.webp";

import preparateur1 from "../../../img/staff/lecompte/lecompte1.webp";
import preparateur2 from "../../../img/staff/lecompte/lecompte2.webp";
import preparateur3 from "../../../img/staff/lecompte/lecompte3.webp";
import preparateur4 from "../../../img/staff/lecompte/lecompte4.webp";
import preparateur5 from "../../../img/staff/lecompte/lecompte5.webp";

import { Img } from "react-image";

const StaffContainer = () => {
  const heroStyle = {
    backgroundImage: `url('${coachImage}')`,
    backgroundPosition: "top left",
    backgroundSize: "125%",
  };

  const adjointStyle = {
    backgroundImage: `url('${adjointImage}')`,
    backgroundPosition: "top left",
    backgroundSize: "50%",
  };

  const preparateurStyle = {
    backgroundImage: `url('${preparateur}')`,
    backgroundPosition: "top left",
    backgroundSize: "110%",
  };

  const adjoint2Style = {
    backgroundImage: `url('${newAdjoint4}')`,
    backgroundPosition: "top left",
    backgroundSize: "50%",
  };

  const swiperGallleryParams = {
    containerClass: "gallery-slider",
    wrapperClass: "swiper-wrapper",
    slideClass: "swiper-slide",
    speed: 400,
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: "auto",
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };

  return (
    <>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={heroStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">JULIEN MAHÉ</h2>
              <p>Coach depuis 2020</p>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
                  Originaire du Finistère, Julien Mahé a débuté ses études en
                  STAPS, où il a obtenu simultanément un diplôme d'entraîneur au
                  Centre de Formation et d'Apprentissage (CFA) de Chalon et à
                  l'Université de Dijon.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="200">
                <p>
                  Arrivé à l’Elan Chalon en 2004, il y restera deux ans en tant
                  qu’entraineur de l’équipe Seniors qui évoluait en Prénationale
                  et passera ensuite au BC Chalon à la tête de l’équipe
                  féminine. En 2008, le breton est contacté par les dirigeants
                  de la JDA Dijon. Il entre au club et devient alors
                  l’entraîneur des espoirs jusqu'en 2010.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="300">
                <p>
                  Avec la descente du club en Pro B, Julien passe entraîneur
                  adjoint de l’équipe professionnelle et devient même
                  responsable du centre de formation à seulement 25 ans jusqu'en
                  2013. La même année, il rejoint le club du BCM Gravelines
                  Dunkerque, où il devient l'entraîneur de l'équipe espoir ainsi
                  que l'entraîneur adjoint de Christian Monschau.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="400">
                <p>
                  En 2017, il est promu entraîneur du club gravelinois pour la
                  saison 2017-2018. Le 5 février 2020, il prend les rênes de
                  l'équipe du Saint-Quentin Basket Ball alors avant-dernière de
                  Pro B. Les deux saisons suivantes, le SQBB termine
                  successivement à la troisième place du championnat et Julien
                  Mahé remporte en 2021 le trophée de meilleur entraîneur de la
                  division.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="500">
                <p>
                  La saison 2022-2023 est alors celle du sacre : à l'issue du
                  dernier match de la saison régulière, le SQBB et
                  Châlon-sur-Saône terminent en tête avec chacun 25 victoires.
                  Avec un point-average favorable (+3) sur son adversaire, le
                  SQBB termine champion de Pro B saison 2022-2023 et intègre
                  l'Élite du basket français, 30 ans après l’avoir quitté. Une
                  nouvelle fois, Julien Mahé décroche le le trophée de meilleur
                  entraineur de PRO B sur la saison 2022-2023.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="gallery section-bg">
        <div
          className="container-fluid"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Swiper {...swiperGallleryParams}>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach1} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach2} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach3} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach4} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach5} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={coach6} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={adjointStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">DOMINIQUE ROINARD </h2>
              <p>Coach adjoint depuis 2024</p>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
                  Orléanais d’origine, Dominique Roinard a tracé son chemin dans
                  le basket français en passant par plusieurs clubs tels que
                  l’Anjou Basket Club, Châlons-en-Champagne, Épinal, Orléans et
                  Denain. C’est à l’ADA Blois qu’il a véritablement laissé son
                  empreinte, contribuant à une première place en Pro B en 2020
                  et, surtout, à la montée en Betclic Élite en 2022.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="200">
                <p>
                  Assistant coach expérimenté, Dominique a su évoluer et
                  s’affirmer au fil des saisons. À Blois, il a travaillé aux
                  côtés de Mickaël Hay, renforçant ses compétences et
                  accompagnant l’équipe vers des résultats remarquables.
                  Désormais au Saint-Quentin Basket-Ball, il intègre le staff de
                  Julien Mahé et s’apprête à découvrir une nouvelle dimension
                  avec la Basketball Champions League, une première dans sa
                  carrière.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="300">
                <p>
                  Fort de son expérience et de sa rigueur, Dominique sera un
                  atout essentiel pour le SQBB dans les défis à venir, que ce
                  soit en Betclic Élite ou sur la scène européenne.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="gallery section-bg">
        <div
          className="container-fluid"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Swiper {...swiperGallleryParams}>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={adjoint1} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            {/*<SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={adjoint2} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={adjoint3} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={adjoint4} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={adjoint5} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>*/}
          </Swiper>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={adjoint2Style}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">KARIM REMIL</h2>
              <p>Coach adjoint depuis 2023</p>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
                  Karim a joué pendant 15 ans au Vevey Riviera Basket, club de
                  basket en Suisse de première division avant de devenir
                  entraîneur et formateur durant 8 années.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="200">
                <p>
                  {" "}
                  Le technicien a ensuite quitté Vevey et la Suisse en 2012 pour
                  intégrer le staff du centre de formation de Dijon en Pro A en
                  ayant comme tuteur un certain Jean-Louis Borg.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="300">
                <p>
                  Karim est alors resté trois ans à la JDA avant de poser ses
                  valises en Lorraine en 2015 pour coacher les cadets et être
                  assistant de Pierre Verdière au SLUC Espoirs.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="400">
                <p>
                  En 2017, Karim prend ensuite la direction de Fos où il y
                  passera 6 saisons aux côtés de Rémi Giuitta puis de Rémy
                  Valin. Il débarque cette année à Saint-Quentin pour composer
                  un trio avec Julien et Antony.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="gallery section-bg">
        <div
          className="container-fluid"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Swiper {...swiperGallleryParams}>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={newAdjoint1} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={newAdjoint2} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={newAdjoint3} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={newAdjoint4} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={preparateurStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">ERIC LECOMTE</h2>
              <p>Préparateur physique depuis 2020</p>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
                  Natif de Seine-et-Marne, Eric possède un diplôme universitaire
                  pro et un master STAPS. Diplôme en poche, il s’est envolé en
                  Australie pour y rejoindre de 2008 à 2010 les Sydney Kings
                  avec un stage en première année puis un rôle de 2ème
                  préparateur physique en deuxième année.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="200">
                <p>
                  Après cette expérience, Éric est revenu en France pour
                  travailler dans le tennis pro et semis pro ainsi que sur des
                  marathons et semis. Puis, direction Gravelines (Pro A) où il
                  rejoint Julien Mahé, l’actuel coach du SQBB, et où les deux
                  hommes vont se côtoyer pendant une saison et demie.
                </p>
              </blockquote>
              <blockquote data-aos="fade-up" data-aos-delay="300">
                <p>
                  Après un nouveau rebond dans le tennis, Éric enchaîne à
                  Strasbourg (Pro A) en 2020. Malheureusement, l’aventure est de
                  courte durée à cause du Covid-19. Depuis, Éric a retrouvé de
                  nouveau Julien à Saint-Quentin et a un rôle important dans la
                  préparation physique au quotidien et dans le développement des
                  joueurs du SQBB
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="gallery section-bg">
        <div
          className="container-fluid"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Swiper {...swiperGallleryParams}>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={preparateur1} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={preparateur2} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={preparateur3} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={preparateur4} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glightbox" data-gallery="images-gallery">
                <Img src={preparateur5} className="img-fluid" alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default StaffContainer;
